import React from "react";
import { Container } from "react-bootstrap";

const CallToAction = (props) => {
  return (
    <section className="cta text-center" style={props.style}>
      <Container>
        <h4 className="pre-title">{props.pretitle}</h4>
        <h2 className="title mt-0">{props.title}</h2>
        {props.btnText && props.btnText !== "" && (
          <div className="text-center">
            {props.btnLink && props.btnLink !== "" ? (
              <a className="color-white btn btn-black" href={props.btnLink}>
                {props.btnText}
              </a>
            ) : (
              <button>{props.btnText}</button>
            )}
          </div>
        )}
      </Container>
    </section>
  );
};

export { CallToAction };
