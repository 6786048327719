import React, { useEffect } from "react";
import { Seo } from "../utils/Seo.js";
import Layout from "../components/Layout/index.js";
import { Container } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";

const testImg3 = "../images/stock_photos/dts_A_New_Way_Of_Work_18.jpg";
const testImg4 = "../images/stock_photos/people-at-table.jpg";
const review = "../images/stock_photos/DTS_005.jpg";
const openingBooks = "../images/stock_photos/JG0A1895.jpg";
const testImg7 = "../images/stock_photos/DTS_011.jpg";
const testImg8 = "../images/stock_photos/DTS_033.jpg";
const uxResearch = "../images/stock_photos/window-postits.jpg";
const phoneondesk = "../images/stock_photos/phone-on-desk.jpg";

const Approach = ({ data }) => {
  return (
    <section className="approach pb-5">
      <h2 className="scrollr-title d-none">
        Our approach to projects
        <span className="scrollr-label">Scroll {" ->"}</span>
      </h2>
      <div className="scrollr">
        <div className="scrollr-item">
          <div className="scrollr-item-img" aria-hidden="true">
            <StaticImage src={testImg3} alt="" />
          </div>
          <div className="scrollr-item-content">
            <h3>Kickoff</h3>
            <p>
              Nice to meet you{" "}
              <span className="emoji" role="img" aria-label="Waving hand emoji">
                👋
              </span>{" "}
              <br />
              At this stage, it's important for us to get to know each other and
              figure out what your goals are. We'll ask you all the tough
              questions so we can understand what you need and make sure we're
              the right fit for your business.
            </p>
          </div>
        </div>
        <div className="scrollr-item">
          <div className="scrollr-item-img" aria-hidden="true">
            <StaticImage src={uxResearch} alt="" />
          </div>
          <div className="scrollr-item-content">
            <h3>Discovery</h3>
            <p>
              Having established that we're a good fit for each other, it's time
              for us to learn. We'll look at things affecting you, your
              competitors and your industry. Once we know the full picture,
              we'll give you a few options on what we can achieve together.
            </p>
          </div>
        </div>
        <div className="scrollr-item">
          <div className="scrollr-item-img" aria-hidden="true">
            <StaticImage src={testImg8} alt="" />
          </div>
          <div className="scrollr-item-content">
            <h3>Paperwork</h3>
            <p>
              Once we find out how we can work together we'll put together a
              proposal that outlines what we'll do and how much it will cost. If
              you like the proposal, we'll move forward by signing a contract
              and getting started on your website.
            </p>
          </div>
        </div>
        <div className="scrollr-item">
          <div className="scrollr-item-img" aria-hidden="true">
            <StaticImage
              className="scrollr-item-img"
              src={openingBooks}
              alt=""
            />
          </div>
          <div className="scrollr-item-content">
            <h3>Creative</h3>
            <p>
              With notes in hand, we'll head to the drawing board to come up
              with the creative portions for your new website. Here we'll create
              an array of info maps, charts, and wireframes to establish how
              everything fits together. We will then follow this up with the
              final design.
              <br />
            </p>
          </div>
        </div>
        <div className="scrollr-item">
          <div className="scrollr-item-img" aria-hidden="true">
            <StaticImage src={testImg4} alt="" />
          </div>
          <div className="scrollr-item-content">
            <h3> Development</h3>
            <p>
              After the design process is complete, we move on to development.
              This is where we start coding the front and back end of the site
              to make it functional and ready for use. We add required features,
              import data, set up a CMS and everything else that’s needed to
              make the designs come to life.
            </p>
          </div>
        </div>
        <div className="scrollr-item">
          <div className="scrollr-item-img" aria-hidden="true">
            <StaticImage src={review} alt="" />
          </div>
          <div className="scrollr-item-content">
            <h3>Review</h3>
            <p>
              When we have an initial design ready, we'll show it to you and get
              your feedback. You can let us know what you think and we'll use
              your comments to make any necessary revisions to the design.
            </p>
          </div>
        </div>
        <div className="scrollr-item">
          <div className="scrollr-item-img" aria-hidden="true">
            <StaticImage src={testImg7} alt="" />
          </div>
          <div className="scrollr-item-content">
            <h3>Launch </h3>
            <p>
              We're so excited that you're launching your new website. We've
              been working hard to make sure it's ready for prime time, and
              we're here to support you as your new site goes live.
            </p>
          </div>
        </div>
        <div className="scrollr-item">
          <div className="scrollr-item-img" aria-hidden="true">
            <StaticImage src={phoneondesk} alt="" />
          </div>
          <div className="scrollr-item-content">
            <h3>Post-Launch </h3>
            <p>
              Congratulations on the launch of your new website! We're excited
              to see what you've created and are here to assist you with your
              long-term goals.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Approach;