import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Seo } from "../utils/Seo.js";
import Layout from "../components/Layout/index.js";
import Approach from "../components/approach.js";
import { CallToAction } from "../components/CallToAction/index.js";

const ProcessPage = ({ data }) => {
  return (
    <Layout>
      <Seo
        title="Our Process | Montebello.digital"
        desc="Bringing your ideas to life."
      />
      <div className="agency-hero mb-3">
        <Container fluid className="agency-hero-container">
          <div className="hero-content">
            <h1 className="pre-title">
            Trusting the process. 
            </h1>
            <h1 className="title">
              Bringing your ideas to life.
            </h1>
          </div>
        </Container>
      </div>

      <Approach />

      <div className="process-cta">
        <CallToAction
          pretitle="Ready to scale your business up a level?"
          title="Let us know what you need."
          btnText="Contact Us"
          btnLink="/contact"
        />
      </div>
    </Layout>
  );
};

export default ProcessPage;
